import { default as awardsSTQZQuDjDSMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/awards.vue?macro=true";
import { default as dashboardcy0rhHpAjIMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/dashboard.vue?macro=true";
import { default as helpW0raNne5soMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/help.vue?macro=true";
import { default as indexCH2TShU3WtMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/index.vue?macro=true";
import { default as organization_45settingsqWG6dd35R1Meta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/organization-settings.vue?macro=true";
import { default as organizationD4qjXr63tiMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/organization.vue?macro=true";
import { default as profileCPZAzAPkg1Meta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/profile.vue?macro=true";
import { default as team_45settingsVRuADTd9GuMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/team-settings.vue?macro=true";
import { default as teamH5YbefWstlMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/team.vue?macro=true";
import { default as billinguhIioLdts2Meta } from "/opt/buildhome/repo/pages/app/[orgSlug]/billing.vue?macro=true";
import { default as helplyR9pQSEnaMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/help.vue?macro=true";
import { default as indexdCy9XeuCTxMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/index.vue?macro=true";
import { default as organization_45settings2LQJ4dH07JMeta } from "/opt/buildhome/repo/pages/app/[orgSlug]/organization-settings.vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as signup71EVNEoLpMMeta } from "/opt/buildhome/repo/pages/signup.vue?macro=true";
export default [
  {
    name: "app-orgSlug-gameSlug-awards___pt",
    path: "/app/:orgSlug()/:gameSlug()/awards",
    meta: awardsSTQZQuDjDSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/awards.vue")
  },
  {
    name: "app-orgSlug-gameSlug-awards___en",
    path: "/en/app/:orgSlug()/:gameSlug()/awards",
    meta: awardsSTQZQuDjDSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/awards.vue")
  },
  {
    name: "app-orgSlug-gameSlug-dashboard___pt",
    path: "/app/:orgSlug()/:gameSlug()/dashboard",
    meta: dashboardcy0rhHpAjIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/dashboard.vue")
  },
  {
    name: "app-orgSlug-gameSlug-dashboard___en",
    path: "/en/app/:orgSlug()/:gameSlug()/dashboard",
    meta: dashboardcy0rhHpAjIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/dashboard.vue")
  },
  {
    name: "app-orgSlug-gameSlug-help___pt",
    path: "/app/:orgSlug()/:gameSlug()/help",
    meta: helpW0raNne5soMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/help.vue")
  },
  {
    name: "app-orgSlug-gameSlug-help___en",
    path: "/en/app/:orgSlug()/:gameSlug()/help",
    meta: helpW0raNne5soMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/help.vue")
  },
  {
    name: "app-orgSlug-gameSlug___pt",
    path: "/app/:orgSlug()/:gameSlug()",
    meta: indexCH2TShU3WtMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/index.vue")
  },
  {
    name: "app-orgSlug-gameSlug___en",
    path: "/en/app/:orgSlug()/:gameSlug()",
    meta: indexCH2TShU3WtMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/index.vue")
  },
  {
    name: "app-orgSlug-gameSlug-organization-settings___pt",
    path: "/app/:orgSlug()/:gameSlug()/organization-settings",
    meta: organization_45settingsqWG6dd35R1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/organization-settings.vue")
  },
  {
    name: "app-orgSlug-gameSlug-organization-settings___en",
    path: "/en/app/:orgSlug()/:gameSlug()/organization-settings",
    meta: organization_45settingsqWG6dd35R1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/organization-settings.vue")
  },
  {
    name: "app-orgSlug-gameSlug-organization___pt",
    path: "/app/:orgSlug()/:gameSlug()/organization",
    meta: organizationD4qjXr63tiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/organization.vue")
  },
  {
    name: "app-orgSlug-gameSlug-organization___en",
    path: "/en/app/:orgSlug()/:gameSlug()/organization",
    meta: organizationD4qjXr63tiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/organization.vue")
  },
  {
    name: "app-orgSlug-gameSlug-profile___pt",
    path: "/app/:orgSlug()/:gameSlug()/profile",
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/profile.vue")
  },
  {
    name: "app-orgSlug-gameSlug-profile___en",
    path: "/en/app/:orgSlug()/:gameSlug()/profile",
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/profile.vue")
  },
  {
    name: "app-orgSlug-gameSlug-team-settings___pt",
    path: "/app/:orgSlug()/:gameSlug()/team-settings",
    meta: team_45settingsVRuADTd9GuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/team-settings.vue")
  },
  {
    name: "app-orgSlug-gameSlug-team-settings___en",
    path: "/en/app/:orgSlug()/:gameSlug()/team-settings",
    meta: team_45settingsVRuADTd9GuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/team-settings.vue")
  },
  {
    name: "app-orgSlug-gameSlug-team___pt",
    path: "/app/:orgSlug()/:gameSlug()/team",
    meta: teamH5YbefWstlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/team.vue")
  },
  {
    name: "app-orgSlug-gameSlug-team___en",
    path: "/en/app/:orgSlug()/:gameSlug()/team",
    meta: teamH5YbefWstlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/[gameSlug]/team.vue")
  },
  {
    name: "app-orgSlug-billing___pt",
    path: "/app/:orgSlug()/billing",
    meta: billinguhIioLdts2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/billing.vue")
  },
  {
    name: "app-orgSlug-billing___en",
    path: "/en/app/:orgSlug()/billing",
    meta: billinguhIioLdts2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/billing.vue")
  },
  {
    name: "app-orgSlug-help___pt",
    path: "/app/:orgSlug()/help",
    meta: helplyR9pQSEnaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/help.vue")
  },
  {
    name: "app-orgSlug-help___en",
    path: "/en/app/:orgSlug()/help",
    meta: helplyR9pQSEnaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/help.vue")
  },
  {
    name: "app-orgSlug___pt",
    path: "/app/:orgSlug()",
    meta: indexdCy9XeuCTxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/index.vue")
  },
  {
    name: "app-orgSlug___en",
    path: "/en/app/:orgSlug()",
    meta: indexdCy9XeuCTxMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/index.vue")
  },
  {
    name: "app-orgSlug-organization-settings___pt",
    path: "/app/:orgSlug()/organization-settings",
    meta: organization_45settings2LQJ4dH07JMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/organization-settings.vue")
  },
  {
    name: "app-orgSlug-organization-settings___en",
    path: "/en/app/:orgSlug()/organization-settings",
    meta: organization_45settings2LQJ4dH07JMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/[orgSlug]/organization-settings.vue")
  },
  {
    name: "app___pt",
    path: "/app",
    meta: indexIbNDbq129sMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/index.vue")
  },
  {
    name: "app___en",
    path: "/en/app",
    meta: indexIbNDbq129sMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "signup___pt",
    path: "/signup",
    component: () => import("/opt/buildhome/repo/pages/signup.vue")
  },
  {
    name: "signup___en",
    path: "/en/signup",
    component: () => import("/opt/buildhome/repo/pages/signup.vue")
  }
]